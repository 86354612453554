import { VideoPlayer } from '@/components/Widgets';
import Interval from '@/components/General/Interval';
import './CameraTab.scss';
import useRooms from '@/compositions/useRooms';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'CameraTab',
    components: {
        VideoPlayer,
        Interval
    },
    setup: () => ({
        ...useRooms()
    }),
    data: () => ({
        selectedLarge: 0
    }),
    computed: {
        cameraIds() {
            return Object.keys(this.rooms);
        },
        largeCameraIds() {
            return Object.keys(this.rooms);
        }
    },
    methods: {
        styleLocation(index) {
            const left = index < 5 ? (index * 20) + '%' : '80%';
            const top = index < 5 ? '80%' : (80 - (index - 4) * 20) + '%';
            return {
                width: '19%',
                height: '19%',
                left,
                top,
                position: 'absolute'
            };
        },
        onUpdate() {
            const length = Object.values(this.largeCameraIds).length;
            if (length !== 0) {
                this.selectedLarge = (this.selectedLarge + 1) % length;
            }
        }
    }
});
